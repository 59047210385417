<template>
  <div class="usl-right__content">
    <div class="usl-right__content--top">
      <label>{{
        isCreateInitiative
          ? "What is a learning initiative?"
          : "What is my learning space?"
      }}</label>
      <p v-if="isCreateInitiative">
        A learning initiative is a program aimed at improving knowledge and
        skills through structured training or education, with the goal of
        increasing efficiency and competitiveness.
      </p>
      <p v-else>
        In your learning space, students or learners can engage with educational
        materials, collaborate with others, and interact with your teachers or
        instructors to enhance their knowledge and skills through the different
        range of resources you provide.
      </p>
    </div>
    <div v-if="!isCreateInitiative" class="usl-right__content--bottom">
      <label>Who has access to my learning space?</label>
      <p>
        Learning spaces are restricted to registered students. This helps to
        ensure that the educational resources and interactions are kept secure
        and are only available to those who are actively participating in the
        learning experience.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isCreateInitiative() {
      return this.$route.name === "CreateInitiative";
    }
  }
};
</script>

<style lang="scss" scoped>
.usl-right__content {
  .usl-right__content--top {
    margin-bottom: 10px;
  }
  label {
    @include body-regular(500);
    margin-bottom: 10px;
  }
  p {
    @include body-medium;
  }
}
</style>
